<template>
  <div class="page-wrapper">
    <TheSidebar />
    <div class="right-content-wrapper">
      <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page="15"
        sort-by="calories"
        class="elevation-1"
        style="width: 100%"
        :loading="spinnerActive"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Subdomains</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="1000px">
              <!-- <template v-slot:activator="{}"> -->
              <!-- <v-btn color="primary" dark class="mb-2" v-on:click="addItem()" >New Item</v-btn> -->
              <!-- </template> -->
              <v-card>
                <v-card-title>
                  <span class="text-h5">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-text-field
                        v-model="editedItem.website_config[1].value"
                        label="Title"
                      ></v-text-field>
                    </v-row>
                    <v-row>
                      <v-text-field
                        v-model="editedItem.website_config[0].value"
                        label="Description"
                      ></v-text-field>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text v-on:click="close">Cancel</v-btn>
                  <v-btn color="blue darken-1" text v-on:click="editedIndex === -1 ? addItemConfirm() : editItemConfirm()">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="1000px">
              <v-card>
                <v-card-title class="text-h5"
                  >Are you sure you want to delete this item?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text v-on:click="closeDelete"
                    >Cancel</v-btn
                  >
                  <v-btn color="blue darken-1" text v-on:click="deleteItemConfirm"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <!-- <v-icon small class="mr-2" v-on:click="editItem(item)">mdi-eye</v-icon> -->
          <v-icon small class="mr-2" v-on:click="editItem(item)">mdi-pencil</v-icon>
          <!-- <v-icon small v-on:click="deleteItem(item)">mdi-delete</v-icon> -->
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" v-on:click="getWebsites">Reset</v-btn>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import {
  fetchWebsites,
  editWebsite,
  addWebsite,
  deleteWebsite,
} from "@/services";
import TheSidebar from "@/components/TheSidebar";

export default {
  name: "ConnectsConfig",
  components: {
    TheSidebar,
  },
  data() {
    return {
      spinnerActive: false,
      dialog: false,
      dialogDelete: false,
      headers: [
        { text: "Website", align: "start", value: "domain" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      items: [],
      editedIndex: -1,
      editedItem: { website_config: [{ value: "" }, { value: "" }] },
      defaultItem: { website_config: [{ value: "" }, { value: "" }] },
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  mounted() {
    this.getWebsites();
  },
  methods: {
    async getWebsites() {
      this.spinnerActive = true;
      await fetchWebsites().then((res) => {
        if (res.data) {
          this.items = res.data;
          this.spinnerActive = false;
        }
      }).catch((error) => {
        console.log(error);
        this.spinnerActive = false;
      });
    },
    addItem() {
      this.editedIndex = -1;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.dialog = true;
    },
    editItem(item) {
      this.editedIndex = 1;
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem(item) {
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
      });
    },
    async deleteItemConfirm() {
      await deleteWebsite()
        .then((response) => {
          console.log(response);
          this.closeDelete();
        })
        .catch((error) => {
          console.log(error);
        });
      this.close();
    },
    async addItemConfirm() {
      await addWebsite(this.editedItem)
        .then((response) => {
          console.log(response);
          this.close();
        })
        .catch((error) => {
          console.log(error);
        });
      this.close();
    },
    async editItemConfirm() {
      console.log("editItemConfirm");
      await editWebsite(this.editedItem)
        .then((response) => {
          console.log(response);
          this.close();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
